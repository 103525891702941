<template>
  <div class="container">
    <div class="container register">
      <div class="row">
        <div class="col-md-3 register-left">
          <img
              src="/Bilder/book.png"
              alt=""
              class="rounded-circle">
          <h3>Herzlich Willkommen</h3>


        </div>
        <div class="col-md-9 register-right">

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <h3 class="register-heading">Anmelden</h3>

              <v-form
                  class="register-form">
                <v-text-field
                    v-model="email"
                    label="E-mail"
                    @keydown.enter="login"
                    required
                    outlined
                ></v-text-field>

                <v-text-field
                    v-model="password"
                    name="input-10-1"
                    hint=""
                    label="Passwort"
                    @keydown.enter="login"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    required
                    outlined
                ></v-text-field>
                <v-row class="mb-5">
                  <v-col
                      cols="12"
                      sm="4"
                      md="4">
                    <v-checkbox
                        v-model="chooseLogin"
                        label="Benutzer"
                        color="success"
                        value="1"
                        hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4">
                    <v-checkbox
                        v-model="chooseLogin"
                        label="Verein"
                        color="info"
                        value="2"
                        hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-btn
                    block
                    color="white--text"
                    class="success"
                    @keydown.enter.native="login"
                    @click="login"
                >
                  <v-icon>mdi-log-in</v-icon>
                  Anmelden
                </v-btn>

                <v-btn
                    block
                    class="mt-2"
                    color="primary"
                    plain
                    @click="forgotpassword"
                >
                  Passwort vergessen?
                </v-btn>
              </v-form>

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "login",

  data() {
    return {
      email: "",
      password: "",
      chooseLogin: '1',
      show1: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },
  methods: {
    login: function () {
      const data = {
        email: this.email,
        password: this.password
      };

      switch (this.chooseLogin) {
        case '1': //User Login
          this.$store.dispatch('UserAuth/login', data)
              .then(() => this.$router.push('/user/home'))
              .catch(err => console.log(err))
          break;

        case '2': //Club Login
          this.$store.dispatch('ClubAuth/login', data)
              .then(() => this.$router.push('/club/home'))
              .catch(err => console.log(err))
          break;
      }


    },
    forgotpassword() {
      let url = process.env.VUE_APP_BACKEND_URL;
      switch (this.chooseLogin) {
        case '1': //User Login
          url = url + 'password/reset';
          window.location.href = url;
          break;

        case '2': //Club Login
          url = url + 'verein/password/reset';
          window.location.href = url;
          break;
      }
    },
  }
}
</script>

<style media="screen">
.register {
  background: -webkit-linear-gradient(left, #32Cd32, #a4f9a4);
  margin-top: 3%;
  padding: 3%;
}

.register-left {
  text-align: center;
  color: #fff;
  margin-top: 4%;
}

.register-left input {
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  width: 60%;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  margin-top: 30%;
  margin-bottom: 3%;
  cursor: pointer;
}

.register-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
}

.register-left img {
  margin-top: 15%;
  margin-bottom: 5%;
  width: 25%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.register-left p {
  font-weight: lighter;
  padding: 12%;
  margin-top: -9%;
}

.register .register-form {
  padding: 10%;
  margin-top: 10%;
}

.btnRegister {
  float: right;
  margin-top: 10%;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  background: #0062cc;
  color: #fff;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
}

.register .nav-tabs {
  margin-top: 3%;
  border: none;
  background: #0062cc;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}

.register .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.register .nav-tabs .nav-link:hover {
  border: none;
}

.register .nav-tabs .nav-link.active {
  width: 100px;
  color: #0062cc;
  border: 2px solid #0062cc;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.register-heading {
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: #495057;
}
</style>
